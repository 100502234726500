import { createIcon } from '@chakra-ui/icons';

export const ShopifyIcon = createIcon({
  displayName: 'ShopifyIcon',
  viewBox: '0 0 100 28.6',
  path: (
    <g>
      <path
        fill="#8DB849"
        d="M11.3,1c0.2,0,0.3,0.1,0.5,0.2C10.6,1.7,9.4,3,8.8,5.8L6.6,6.4C7.3,4.4,8.7,1,11.3,1z M12.4,2
		c0.2,0.6,0.4,1.3,0.4,2.4c0,0.1,0,0.1,0,0.2L9.9,5.4C10.5,3.3,11.5,2.4,12.4,2z M15,3.8l-1.3,0.4c0-0.1,0-0.2,0-0.3
		c0-0.9-0.1-1.6-0.3-2.2C14.1,1.9,14.7,2.8,15,3.8z M21.5,5.4c0-0.1-0.1-0.2-0.2-0.2C21.1,5.2,19,5,19,5s-1.5-1.5-1.7-1.6
		c-0.2-0.2-0.5-0.1-0.6-0.1c0,0-0.3,0.1-0.8,0.3c-0.5-1.4-1.4-2.7-2.9-2.7c0,0-0.1,0-0.1,0c-0.4-0.6-1-0.8-1.5-0.8
		C7.8,0,6.1,4.5,5.5,6.8C4.7,7,3.9,7.3,3,7.6c-0.8,0.2-0.8,0.3-0.9,1C2,9.1,0,24.9,0,24.9l15.9,3l8.6-1.9C24.5,26,21.5,5.6,21.5,5.4
		z"
      />
      ,
      <path
        fill="#5A863E"
        d="M21.2,5.2C21.1,5.2,19,5,19,5s-1.5-1.5-1.7-1.6c-0.1-0.1-0.1-0.1-0.2-0.1l-1.2,24.6l8.6-1.9c0,0-3-20.4-3-20.6
		C21.5,5.3,21.3,5.2,21.2,5.2"
      />
      ,
      <path
        fill="#FFFFFE"
        d="M13,10l-1.1,3.2c0,0-0.9-0.5-2.1-0.5c-1.7,0-1.8,1-1.8,1.3c0,1.4,3.8,2,3.8,5.4c0,2.7-1.7,4.4-4,4.4
		c-2.7,0-4.1-1.7-4.1-1.7l0.7-2.4c0,0,1.4,1.2,2.6,1.2c0.8,0,1.1-0.6,1.1-1.1c0-1.9-3.1-2-3.1-5.1c0-2.6,1.9-5.1,5.6-5.1
		C12.3,9.5,13,10,13,10"
      />
      ,
      <path
        fill="#1A1919;"
        d="M34.6,15.9c-0.9-0.5-1.3-0.9-1.3-1.4c0-0.7,0.6-1.1,1.6-1.1c1.1,0,2.1,0.5,2.1,0.5l0.8-2.4
		c0,0-0.7-0.6-2.8-0.6c-3,0-5,1.7-5,4.1c0,1.4,1,2.4,2.2,3.1c1,0.6,1.4,1,1.4,1.6c0,0.6-0.5,1.2-1.5,1.2c-1.4,0-2.8-0.7-2.8-0.7
		l-0.8,2.4c0,0,1.2,0.8,3.3,0.8c3,0,5.2-1.5,5.2-4.2C37,17.7,35.9,16.6,34.6,15.9 M46.7,10.8c-1.5,0-2.7,0.7-3.6,1.8l0,0l1.3-6.8H41
		l-3.3,17.3h3.4l1.1-5.9c0.4-2.2,1.6-3.6,2.7-3.6c0.8,0,1.1,0.5,1.1,1.3c0,0.5,0,1-0.1,1.5l-1.3,6.8h3.4l1.3-7
		c0.1-0.7,0.2-1.6,0.2-2.2C49.5,12,48.5,10.8,46.7,10.8 M55.4,20.7c-1.2,0-1.6-1-1.6-2.2c0-1.9,1-5.1,2.8-5.1c1.2,0,1.6,1,1.6,2
		C58.2,17.6,57.2,20.7,55.4,20.7z M57.1,10.8c-4.1,0-6.8,3.7-6.8,7.8c0,2.6,1.6,4.7,4.7,4.7c4,0,6.7-3.6,6.7-7.8
		C61.7,13.1,60.3,10.8,57.1,10.8z M67.1,20.8c-0.9,0-1.4-0.5-1.4-0.5l0.6-3.2c0.4-2.1,1.5-3.5,2.7-3.5c1,0,1.4,1,1.4,1.9
		C70.3,17.7,69,20.8,67.1,20.8z M70.4,10.8c-2.3,0-3.6,2-3.6,2h0l0.2-1.8h-3c-0.1,1.2-0.4,3.1-0.7,4.5l-2.4,12.4h3.4l0.9-5h0.1
		c0,0,0.7,0.4,2,0.4c4,0,6.6-4.1,6.6-8.2C73.9,12.9,72.9,10.8,70.4,10.8z M78.7,6c-1.1,0-1.9,0.9-1.9,2c0,1,0.6,1.7,1.6,1.7h0
		c1.1,0,2-0.7,2-2C80.4,6.7,79.7,6,78.7,6 M74,23.1h3.4l2.3-12h-3.4L74,23.1z M88.3,11.1h-2.4l0.1-0.6c0.2-1.2,0.9-2.2,2-2.2
		c0.6,0,1.1,0.2,1.1,0.2l0.7-2.7c0,0-0.6-0.3-1.8-0.3c-1.2,0-2.4,0.3-3.3,1.1c-1.2,1-1.7,2.4-2,3.8l-0.1,0.6H81l-0.5,2.6h1.6
		l-1.8,9.5h3.4l1.8-9.5h2.3L88.3,11.1z M96.4,11.1c0,0-2.1,5.3-3.1,8.2h0c-0.1-0.9-0.8-8.2-0.8-8.2h-3.6l2,11c0,0.2,0,0.4-0.1,0.6
		c-0.4,0.8-1.1,1.5-1.8,2c-0.6,0.5-1.4,0.8-1.9,1l0.9,2.9c0.7-0.1,2.1-0.7,3.3-1.8c1.5-1.4,3-3.7,4.4-6.7l4.1-8.9L96.4,11.1z"
      />
    </g>
  ),
});

export const ParcelsIcons = createIcon({
  displayName: 'ShopifyIcon',
  viewBox: '0 0 65.9 64.9',
  path: (
    <path
      d="M47.4,64c-0.1,0-0.3,0-0.3-0.1h0L32.6,55l-14.2,8.8c-0.1,0.1-0.3,0.3-0.6,0.3c-0.1,0-0.3,0-0.3-0.1h0L2.3,54.5
	C2.2,54.4,2,54.2,2,53.8V37.6c0-0.3,0.2-0.6,0.3-0.8l0.1-0.1l14.5-9V12.2c0-0.3,0.2-0.6,0.3-0.8l0.1-0.1l15-9.1c0.3-0.2,0.6-0.2,1,0
	l15,9.3c0.1,0.1,0.3,0.3,0.3,0.8V28l14.5,8.9c0.1,0.1,0.3,0.3,0.3,0.8v16.2c0,0.3-0.2,0.6-0.3,0.8l-0.1,0.1l-15.1,9
	C47.9,63.9,47.7,64,47.4,64z M47.4,62.9C47.4,62.9,47.4,62.9,47.4,62.9l0.2-0.1v-0.1H47L47.4,62.9z M33.8,53.3l12.9,8V47.5l-12.9-8
	V53.3z M48.6,47.5v13.8l12.9-8V39.5L48.6,47.5z M18.9,47.5v13.8l12.9-8V39.5L18.9,47.5z M4,53.3l12.9,8V47.5L4,39.5V53.3z
	 M32.8,53.9l8.7,5.3L32.8,53.9L32.8,53.9z M62.5,53.9l-0.9,0.6L62.5,53.9C62.5,53.9,62.5,53.9,62.5,53.9z M62.5,37.7v16.2
	c0,0,0,0,0,0L62.5,37.7z M3,37.6C3,37.6,3,37.6,3,37.6l0,16.2V37.7l6.5,4l0,0l-6.4-4l14.8-9.2l4.2,2.5l-4.2-2.6v-0.1L3,37.6z
	 M13.8,44.4l4.1,2.6v0l14.9-9.2l0,0l-14.8,9.2L13.8,44.4z M47.6,46.9L47.6,46.9l14.9-9.2l-8.1-5l0,0l8,5L47.6,46.9z M41.1,41.7
	l6.5,4l13-8l-6.2-3.8L41.1,41.7z M11.4,41.7l6.5,4l13-8l-6.2-3.8L11.4,41.7z M34.6,37.7l4.6,2.8l13.3-7.8l-4.9-3L34.6,37.7z
	 M4.9,37.7l4.6,2.8l13.3-7.8l-4.9-3L4.9,37.7z M24.7,32.7l8,5l0,0L24.7,32.7L24.7,32.7z M28.6,18.9l4.2,2.6v16.1V21.5l7.9-4.9
	l-7.9,4.8L28.6,18.9z M33.8,22.1v13.8l12.9-8V14.1L33.8,22.1z M18.9,27.9l12.9,8V22.1l-12.9-8V27.9z M47.6,28.5L47.6,28.5l0.1-16.2
	L47.6,28.5L47.6,28.5z M17.9,12.2C17.9,12.2,17.9,12.2,17.9,12.2l0,15.9V12.3l6.5,4L34.9,10l-10.5,6.1L17.9,12.2l2.3-1.4L17.9,12.2z
	 M26.3,16.3l6.5,4l13.1-8l-6.3-3.9L26.3,16.3z M19.8,12.2l4.6,2.8l13.3-7.8l-4.9-3L19.8,12.2z"
    />
  ),
});
